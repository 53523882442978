import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isCartIconExperimentOn } from './utils';
import { Cart as ClassicCartIcon } from '@ux/cart';
import '@ux/icon/cart/styles';
import CartIcon from './CartIcon';
import { trfq } from '@px/react-utils';

const CartIconLoader = (props) => {
  const [newCartIcon, setNewCartIcon] = useState(null);
  const mountedRef = useRef(true);

  const fetchExperiment = useCallback(async () => {
    if (props.appKey) {
      isCartIconExperimentOn().then(res => {
        if (!mountedRef.current) return null;
        if (res === true) {
          return setNewCartIcon(true);
        }
        return setNewCartIcon(false);
      });
    } else {
      trfq.logImpression(`gce.cart.icon.no-appkey.impression`);
      return setNewCartIcon(false);
    }
  }, [mountedRef]);

  useEffect(() => {
    fetchExperiment();
    return () => (mountedRef.current = false); // clean up so we don't try to set state on an unmounted component
  }, [fetchExperiment]);

  if (newCartIcon === null) {
    return null;
  }

  const Component = newCartIcon ? CartIcon : ClassicCartIcon;
  return (
    <div id='cart-icon-wrapper'>
      <Component id='cart-icon' { ...props } />
    </div>
  );
};

CartIconLoader.propTypes = {
  appKey: PropTypes.string
};

export default CartIconLoader;
