import { trfq } from '@px/react-utils';

const hosts = [
  'dev-godaddy.com',
  'dev-secureserver.net',
  'dev-123-reg.co.uk',
  'test-godaddy.com',
  'test-secureserver.net',
  'test-123-reg.co.uk',
  'ote-godaddy.com',
  'ote-secureserver.net',
  'godaddy.com',
  'secureserver.net',
  '123-reg.co.uk'
];

export const getHost = (hostname) => {
  const host = hosts.find(h => hostname?.includes(h));
  return host || 'secureserver.net';
};

export const isCartIconExperimentOn = async () => {
  const host = getHost(window.location.hostname);
  const url = `https://cart.${host}/checkoutapi/v1/carticon/experiment`;
  const options = { method: 'GET', credentials: 'include' };
  options.headers = { 'content-type': 'application/json' };
  try {
    const res = await fetch(url, options);
    if (!res.ok) {
      trfq.logImpression(`gce.cart.icon.experiment-error-${res.status}.impression`);
      return false;
    }
    return await res.json();
  } catch (error) {
    trfq.logImpression('gce.cart.icon.experiment-error.impression');
    return false;
  }
};
